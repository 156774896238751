<template>
  <div :class="{ selected }" class="card" @click="$emit('click')">
    <img :src="url" :alt="name" />
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    letter: String,
    rank: String,
    suit: String,
    type: String,
    selected: Boolean
  },

  computed: {
    name() {
      return this.letter || `${this.rank} of ${this.suit}`
    },

    url() {
      const { letter, rank, suit, type } = this
      if (!letter && !rank) return require(`@/assets/cards/${type}.jpg`)

      const card = `${letter || ''}${rank || ''}${suit || ''}`
      return require(`@/assets/cards/${card}.jpg`)
    }
  }
}
</script>

<style lang="scss">
.card {
  display: inline-block;
  width: 10em;
  height: 15.8em;
  border-radius: 0.5em;
  overflow: hidden;
  margin-left: -5em;
  margin-top: -7.9em;
  box-shadow: 0 0 3px #00000080;
  transform: translate(0);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
