import mitt from 'mitt'

const deferred = () => {
  let res, rej

  const promise = new Promise((resolve, reject) => {
    res = resolve
    rej = reject
  })

  promise.resolve = res
  promise.reject = rej

  return promise
}

const ranks = ['3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K']
const suits = ['clubs', 'diamonds', 'hearts', 'spades', 'stars']
const combos = ranks.map(rank => suits.map(suit => ({ rank, suit })))
const deck = [].concat(...combos, { rank: 'Joker', suit: '' })
deck.forEach((card, i) => (card.id = i))

const game = mitt()
Object.assign(game, { ranks, suits, deck })

game.connect = async () => {
  if (game.ws) game.ws.close()

  const lock = deferred()
  const ws = new WebSocket(process.env.VUE_APP_API)
  game.ws = ws

  ws.onopen = () => lock.resolve()
  ws.onmessage = e => {
    const { type, data } = JSON.parse(e.data)
    game.emit(type, data)
    console.log(type, data)
  }
  ws.onclose = () => location.reload()

  return lock
}

game.send = (type, data) => {
  const msg = JSON.stringify({ type, data })
  game.ws.send(msg)
}

export default game
